<style scoped>
.fewerPadding {
  padding: 10px;
}

.small-text {
  font-size: 0.6rem;
  line-height: 1rem;
}

#app .group {
  background-color: gray; /* orange darken-3 */
  border-left: 4px solid gray;
  border-right: 4px solid gray;
  border-bottom: 4px solid gray;
  margin-bottom: 4px;
}

/* we need to be specific with #app to override the other rules */
#app .active {
  background-color: #477b7e; /* primary */
  border-color: #477b7e;
  color: #477b7e;
}

/* we need to be specific with #app to override the other rules */
#app .activeText {
  font-weight: bold;
}

/* correct has to be under highlight */
#app .correct {
  background-color: #4caf50 !important;
  border-color: #4caf50 !important;
}

.fewerPaddingGroup {
  padding: 6px;
}

.placeholderBox {
  min-height: 60px;
  border: 2px solid rgb(192, 192, 192);
  border-radius: 4px;
  background-color: #f5f5f5;
  transition: background-color 0.5s ease;
  margin-bottom: 2px;
}

#app .placeholderBoxText {
  font-style: italic;
  color: grey;
}

.moveable {
  cursor: move;
}

.moveableElementStyle {
  padding: 1px;
}

.moveableElementStyleTextElement {
  max-width: max-content;
}

/* .moveableElementStyleImageElement {
  width: 64%; */
/* left: 50%; */
/* top: 50%; */
/* transform: translate(-50%);
} */

/* .moveableElementStyleImageElementSmaller {
  width: 60%;
} */

.movableElementStyleImageElementContainer {
  padding: 0px;
}

#app .dropDenied {
  transition: all 0.2s ease;
  background-color: #fb8c00; /* warning color */
  border-color: #fb8c00;
  opacity: 0.2;
}
</style>
<template>
  <v-card
    :ref="'group'"
    class="group"
    :class="{ correct: isCorrect, active: isActive, dropDenied: isDropDenied }"
    outlined
  >
    <v-card-text
      class="text-center white--text fewerPaddingGroup"
      :class="{ activeText: isActive }"
      v-html="groupName"
    />
    <!-- group -->
    <draggable
      v-model="elements"
      :group="groupConfig(maximalAmountElementsInGroup)"
      @start="ripple($refs.group)"
      @end="removeDropDeniedLocal"
      :class="{ placeholderBox: true || elements.length < 1 }"
      draggable=".moveable"
      ghostClass="ghostClass"
      @change="change"
      :forceFallback="isSafari()"
    >
      <template v-if="elements.length > 0">
        <v-col
          v-for="card in elements"
          :key="card.id"
          :class="[
            { moveable: !isCorrect },
            { moveableElementStyleTextElement: elements[0].text },
          ]"
          class="moveableElementStyle"
        >
          <v-card>
            <!-- text -->
            <v-card-text
              v-if="card.text"
              class="small-text fewerPadding"
              v-text="card.text"
            ></v-card-text>

            <!-- image -->
            <v-container
              v-if="card.image"
              class="movableElementStyleImageElementContainer"
            >
              <AppExpandableImage
                :class="[
                  { moveableElementStyleImageElement: card.image },
                  {
                    moveableElementStyleImageElementSmaller: useSmallerImageElement(),
                  },
                ]"
                :src="card.image"
                contain
                :parentId="parentId"
                :elementId="card.id"
              />
            </v-container>

            <!-- video -->
            <v-container
              v-if="card.video"
              style="padding-bottom: 25px"
            >
              <v-img
                src="@/assets/DragAndDrop.svg"
                style="max-width: 20%; margin-left: 80%;margin-bottom: 5px;"
              ></v-img>
              <video preload="auto" controls width="100%">
                <source :src="card.video" type="video/mp4" />
              </video>
            </v-container>
          </v-card>
        </v-col>
      </template>
      <!-- has to be under the v-col otherwise the drag and drop doesn't work properly -->
      <v-card-text v-if="elements.length < 1" class="placeholderBoxText"
        >Elemente hier platzieren</v-card-text
      >
    </draggable>
    <!-- d = display ... show smaller button when sm -->
    <AppButtonCheck
      class="d-none d-md-flex"
      :class="{ correct: isCorrect }"
      style="width: 100%"
      color="primary"
      @click="check()"
    ></AppButtonCheck>
    <AppButtonCheck
      class="d-none d-sm-flex d-md-none"
      :class="{ correct: isCorrect }"
      small
      style="width: 100%"
      color="primary"
      @click="check()"
    ></AppButtonCheck>
  </v-card>
</template>

<script>
import draggable from "vuedraggable";
import AppButtonCheck from "@/common/AppButtonCheck";
import AppExpandableImage from "@/common/AppExpandableImage";
import AppHelper from "@/common/AppHelper";

export default {
  name: "LernbausteinGruppenGruppe",
  components: {
    draggable,
    AppButtonCheck,
    AppExpandableImage,
  },
  props: {
    /* The initial group name that is displayed as long as the real category name is revealed */
    initialGroupName: { String, required: true },

    /* Element that holds all possible categories. This is needed to create the correct response code */
    possibleCategories: { Array, required: true },

    /* Id which category should be represented by this group, therefore we have to display the category name as the group name */
    chosenCategory: Number,

    /* Indicator how many elements are allowed in this group */
    maximalAmountElementsInGroup: Number,

    /* After how many items should a response be available */
    checkAfterHowManyItems: Number,

    /* Should a response only be available for the exact item count?*/
    checkAfterExactItemCount: Boolean,

    /* Group id to identify this group */
    groupId: { Number, required: true },

    /* Indicator if this group should be displayed as active */
    isActive: Boolean,

    /* Array of all posible responses which could be chosed from */
    responses: { Array, required: true },

    parentId: { String, required: true },
  },
  data() {
    return {
      /* Current elements assigned to this group */
      elements: [],

      /* Locale variable to recreate the initial group name when using the reset functionality */
      groupName: this.initGroupName(),

      /* Indicator if this group should be displayed as correct */
      isCorrect: false,

      /* Indicator if the drop is denied */
      isDropDenied: false,
    };
  },
  methods: {
    initGroupName: function() {
      if (this.chosenCategory) {
        // if we have a chosen category we try to get the name of that category
        let elementArray = this.possibleCategories.filter(
          (category) => category.id == this.chosenCategory,
          this
        );
        if (elementArray) {
          return elementArray[0].name;
        }
      }
      // if no category was chosen or we couldn't get the name we set it to the initial group name
      return this.initialGroupName;
    },
    groupConfig: function(maxCount) {
      let that = this;
      return {
        name: "things",
        put: function(to) {
          if (that.isCorrect) {
            // the group is correct, so ne further drop is allowed
            that.isDropDenied = true;
            that.$emit("groupLimitReached", that.groupName, that.isCorrect);
            return false;
          }
          if (!maxCount) {
            // if no maxCount is defined we allow all drops
            return true;
          }
          if (to.el.children.length >= maxCount) {
            that.isDropDenied = true;
            that.$emit("groupLimitReached", that.groupName, that.isCorrect);
          }
          return to.el.children.length < maxCount;
        },
      };
    },

    /* called from parent */
    removeDropDenied: function() {
      this.isDropDenied = false;
    },

    removeDropDeniedLocal: function() {
      this.$emit("removeDropDenied");
    },

    /* check this group */
    check: function() {
      // this.changeActiveGroup();
      if (this.elements.length < this.checkAfterHowManyItems) {
        // we have fewer items in our group that the amount we should deliver a response for
        let response = this.getResponse("0");
        // update two way binding with parent
        this.$emit("responseChanged", this._self, response);
        return;
      }

      if (
        this.checkAfterExactItemCount &&
        this.elements.length != this.checkAfterHowManyItems
      ) {
        // we should give a reponse for an exact number of item count, but we have more elements so ...
        let response = {
          category: null,
          text: `Es gibt zu viele Elemente in dieser Gruppe, um eine sinnvolle Rückmeldung geben zu können. Bitte entfernen Sie so viele Elemente bis nur noch ${this.checkAfterHowManyItems} enthalten sind.`,
        };
        this.$emit("responseChanged", this._self, response);
        return;
      }

      let countMap = new Map();

      // count the elements in our group for their category
      for (var element of this.elements) {
        // if our map has already an element of this category we just increase the counter by one
        if (countMap.has(element.category)) {
          var currentCount = countMap.get(element.category);
          countMap.set(element.category, ++currentCount);
        } else {
          // if our map has no element of this category we add the key with an initial value of one
          countMap.set(element.category, 1);
        }
      }

      // we translate the count into a useable key
      // the schema is:
      // category1CountCategory2Count...
      let stringKey = "";
      for (var category of this.possibleCategories) {
        var count = countMap.get(category.id);
        if (count != null) {
          stringKey += count;
        } else {
          stringKey += "0"; // if no elements for this category are present we add a '0'
        }
      }

      // now we have to get the right response
      let response = this.getResponse(stringKey);

      if (response.category) {
        // if the category is set, we know we have a complete group
        // we should remove all draggable functionality (isCorrect = true)
        // but we have to check if maybe all elements in this group are from the same category but this group represents another category
        if (!this.chosenCategory) {
          this.groupName = response.category.name; // we have no chosen category, so we have to get the group name
          this.isCorrect = true;
        } else {
          this.isCorrect = this.chosenCategory == response.category.id; // if we have a chosen category it has to be the one from the repsonse
          if (!this.isCorrect) {
            response.category = null;
            response.text =
              "Die Elemente passen zwar zusammen, doch sind sie in der falschen Gruppe.";
          }
        }
      }

      // update two way binding with parent
      this.$emit("responseChanged", this._self, response);
    },

    getResponse: function(key) {
      for (let i = 0; i < this.responses.length; i++) {
        if (this.responses[i].id == key) {
          return {
            category: this.responses[i].category,
            text: this.responses[i].text,
          };
        }
      }

      let number = this.checkAfterHowManyItems;
      return {
        text: `Bitte ziehen Sie ${number} Elemente in eine Gruppe.`,
      };
    },

    reset: function() {
      // this.elements.splice(0); // empty array
      this.elements = [];
      this.groupName = this.initGroupName();
      this.isCorrect = false;
      this.isDropDenied = false;
    },

    ripple: function(ref) {
      // this is a necessary workaround
      // if we drag an element from a group to the overview the created ripple effect form the click event will stay visible
      setTimeout(function() {
        ref.$el.dispatchEvent(new Event("mouseup"));
      }, 300);
    },

    useSmallerImageElement: function() {
      /* If we have a larger group we can make the elements within smaller */
      return this.$refs.group.$el.clientWidth > 400;
    },

    change: function(changeEvent) {
      this.$emit("change", changeEvent, this.groupName);
    },
    isSafari() {
      /*
        https: //github.com/SortableJS/Sortable/issues/1571#issuecomment-753500530
        Safari has an issue with dnd so we need an detection to decide if we want to use the fallback method of sortablejs
        we just can't always use the fallback method because in firefox if we drag horizontaly on the position of another element our expandImage mechanismn is triggered :(
        */
      return AppHelper.isSafari();
    },
  },
};
</script>
