<style scoped>
.small-text {
  font-size: 0.6rem;
  line-height: 1rem;
}

.height {
  height: 100%;
}

.fewerPadding {
  padding: 10px;
}

.disableSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by any browser but < IE9 */
}

.ghostClass {
  max-width: max-content; /* very important, otherwise the element if dropped in a group will not be displayed well */
}

.maxImageHeight {
  max-height: 130px;
  min-height: 130px;
  min-width: 130px;
}

#app .placeholderBoxArea {
  /* min-height: 100px; */
  border: 2px dashed rgb(192, 192, 192);
  border-radius: 4px;
  background-color: #f5f5f5;
  font-style: italic;
  transition: background-color 0.5s ease;
  margin-left: 2px;
}

#app .placeholderBoxArea .v-card__text {
  color: grey;
}

.workOrder {
  font-size: 16px;
}
.imageWorkOrder {
  margin-inline-end: 0px;
  background-color: white;
  height: 100%;
}
.flexGruppe {
  display: flex;
  align-items: center; /*oderflex-start*/
  background-color: #f5f5f5;
}
@media screen and (max-width: 885px) {
  .flex-grow-1 {
    max-width: 90%;
  }
}
</style>
<template>
  <div class="lbst-border">
    <v-row no-gutters style="flex-wrap: nowrap" class="justify-space-between">
      <!-- workOrder -->
      <v-card outlined class="flex-grow-1">
        <div class="flexGruppe">
          <v-card-text class="workOrder" v-html="workOrder"></v-card-text>
          <div style="height: 100%; max-width: 30%; background-color: white;">
            <div v-if="this.workOrderImage" class="imageWorkOrder">
              <AppExpandableImage
                contain
                :src="this.workOrderImage"
                elementId="GruppenWorkOrderImg"
                parentId="Gruppen"
              >
              </AppExpandableImage>
            </div>
          </div>
        </div>
      </v-card>

      <!-- Info-Dialog -->
      <v-card outlined>
        <v-row no-gutters align="center" style="height: 100%">
          <AppDialogInfo
            :elementId="this.id"
            title="Video-Anleitung für das interaktive Element"
          >
            <video
              preload="auto"
              controls
              width="60%"
              style="margin-left: 20%"
              @play="videoPlayerEvent($event, 'Hilfevideo_LBST_Gruppen.mp4')"
              @fullscreenchange="
                videoPlayerEvent($event, 'Hilfevideo_LBST_Gruppen.mp4')
              "
              @webkitfullscreenchange="
                videoPlayerEvent($event, 'Hilfevideo_LBST_Gruppen.mp4')
              "
              @mozfullscreenchange="
                videoPlayerEvent($event, 'Hilfevideo_LBST_Gruppen.mp4')
              "
              @msfullscreenchange="
                videoPlayerEvent($event, 'Hilfevideo_LBST_Gruppen.mp4')
              "
              @ended="videoPlayerEvent($event, 'Hilfevideo_LBST_Gruppen.mp4')"
              @pause="videoPlayerEvent($event, 'Hilfevideo_LBST_Gruppen.mp4')"
              @seeking="videoPlayerEvent($event, 'Hilfevideo_LBST_Gruppen.mp4')"
              @enterpictureinpicture="
                videoPlayerEvent($event, 'Hilfevideo_LBST_Gruppen.mp4')
              "
              @leavepictureinpicture="
                videoPlayerEvent($event, 'Hilfevideo_LBST_Gruppen.mp4')
              "
            >
              <!-- @seeking="videoPlayerEvent($event, '4er-System um 1 weiterzählen_201103_YB.mp4')" -->
              <source
                src="@/assets/hilfevideos/Hilfevideo_LBST_Gruppen_B.mp4"
                type="video/mp4"
              />
            </video>
          </AppDialogInfo>
        </v-row>
      </v-card>
    </v-row>

    <!-- Area for the elements -->
    <v-row no-gutters align="center">
      <v-col cols="12">
        <draggable
          v-model="internalElementState"
          group="things"
          @start="removeDropDenied"
          @end="removeDropDenied"
          @change="elementsChanged"
          class="row row--dense"
          draggable=".moveable"
          :forceFallback="isSafari()"
        >
          <!-- If we are in portrait mode (sm) we give those cards some extra space -->
          <template v-if="internalElementState.length > 0">
            <v-col
              v-for="card in internalElementState"
              :key="card.id"
              :sm="card.flex + 1"
              :md="card.flex"
              style="cursor: move"
              class="moveable"
            >
              <template v-if="card.text">
                <!-- if our elements are only text -->
                <v-card outlined :elevation="2" :key="card.id" class="height">
                  <v-card-text
                    v-if="card.text"
                    class="small-text fewerPadding"
                    v-text="card.text"
                  ></v-card-text>
                </v-card>
              </template>
              <template v-if="card.image">
                <v-card class="height" style="padding: 6px">
                  <AppExpandableImage
                    :src="card.image"
                    contain
                    :parentId="id"
                    :elementId="card.id"
                  />
                </v-card>
              </template>

              <template v-if="card.video">
                <v-card class="height" :key="card.id" style="padding: 6px">
                  <v-img
                    src="@/assets/DragAndDrop.svg"
                    style="max-width: 20%; margin-left: 80%; margin-bottom: 5px;"
                  ></v-img>
                  <video preload="auto" controls width="100%">
                    <source :src="card.video" type="video/mp4" />
                  </video>
                </v-card>
              </template>
            </v-col>
          </template>
          <v-col cols="3" v-if="internalElementState.length < 1">
            <v-card flat class="placeholderBoxArea">
              <v-card-text>Elemente hier platzieren</v-card-text>
            </v-card>
          </v-col>
        </draggable>
      </v-col>
    </v-row>

    <!-- Area for responses -->
    <v-row no-gutters justify="center">
      <v-col cols="12">
        <v-card outlined>
          <v-row
            no-gutters
            align="center"
            :style="'min-height: ' + this.feedbackAreaMinHeight + 'px'"
          >
            <v-col>
              <v-card-text>
                <!-- warning -->
                <AppAlertTooltip
                  v-show="warning"
                  :textHtml="warningText"
                  type="warning"
                  :parentId="id"
                />

                <!-- hint -->
                <AppAlertTooltip
                  v-show="!response.category && response.text"
                  :textHtml="response.text"
                  :expandable="false"
                  :expandableInitialOpen="true"
                  type="info"
                  :parentId="id"
                />

                <!-- success -->
                <AppAlertTooltip
                  v-show="response.category && response.text"
                  :textHtml="response.text"
                  :expandable="false"
                  :expandableInitialOpen="true"
                  type="success"
                  :parentId="id"
                />
              </v-card-text>
            </v-col>
          </v-row>
          <resize-observer @notify="handleResize" />
        </v-card>
      </v-col>
    </v-row>

    <!-- Area for groups -->
    <v-row no-gutters>
      <!-- whole width -->
      <v-col cols="12">
        <v-row dense justify="center">
          <v-col
            :cols="12 / possibleCategories.length"
            v-for="(group, index) in possibleCategories"
            :key="index"
          >
            <LernbausteinGruppenGruppe
              :ref="'refGroup' + index"
              :initial-group-name="'Gruppe ' + (index + 1)"
              :possibleCategories="possibleCategories"
              :chosenCategory="
                showGroupNames ? possibleCategories[index].id : null
              "
              :maximalAmountElementsInGroup="maximalAmountElementsInGroup"
              :checkAfterHowManyItems="checkAfterHowManyItems"
              :checkAfterExactItemCount="checkAfterExactItemCount"
              :responses="responses"
              :groupId="index"
              :isActive="activeGroup === index"
              @responseChanged="responseChanged"
              @groupLimitReached="groupLimitReached"
              @removeDropDenied="removeDropDenied"
              @change="elementsChanged"
              :parentId="id"
            ></LernbausteinGruppenGruppe>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row no-gutters justify="space-between">
      <AppButtonReset @click="reset()"></AppButtonReset>
    </v-row>
    <AppAlternativeContent
      :id="id"
      :exerciseId="id"
      :trials="trials"
      :solved="solved"
      :reset="resetLBST"
      v-on:reset="resetLBST = false"
      v-if="showAlternative"
    >
      <template v-slot:alternative-content>
        <slot name="alternative-content"></slot>
      </template>
    </AppAlternativeContent>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import LernbausteinGruppenGruppe from "./LernbausteinGruppenGruppe";
import AppAlertTooltip from "@/common/AppAlertTooltip";
import AppButtonReset from "@/common/AppButtonReset";
import AppExpandableImage from "@/common/AppExpandableImage";
import AppDialogInfo from "@/common/AppDialogInfo";
import AppAlternativeContent from "@/common/AppAlternativeContent";
import AppHelper from "@/common/AppHelper";

export default {
  name: "LernbausteinGruppen",
  components: {
    draggable,
    LernbausteinGruppenGruppe,
    AppAlertTooltip,
    AppButtonReset,
    AppExpandableImage,
    AppDialogInfo,
    AppAlternativeContent,
  },
  // created: function() {
  //   this.restoreState();
  // },
  mounted: function() {
    this.restoreState();
  },
  props: {
    /* Falls die Alternativen Erläuterungen nicht angezeigt werden sollen */
    showAlternative: { Boolean, default: true },

    /* Indicator if the group names, defined in possible categories, should be visible */
    showGroupNames: Boolean,

    /* How many elements should be allowed in one group */
    maximalAmountElementsInGroup: Number,

    /* After how many items should a response be available */
    checkAfterHowManyItems: Number,

    /* Should a response only be available for the exact item count?*/
    checkAfterExactItemCount: Boolean,

    /* The work order that should be displayed */
    workOrder: { String, required: true },

    workOrderImage: { Object, required: false },

    /* Elements we receive that we should display */
    elements: { Array, required: true },

    /* Element that holds all possible categories. This is needed to create the correct response code */
    possibleCategories: { Array, required: true },

    /* Array of all posible responses which could be chosed from */
    responses: { Array, required: true },

    /* unique id for the store */
    id: { String, required: true },
  },
  data() {
    return {
      /* Indicator which is the current active group */
      activeGroup: -1,

      /* Variable that holds the current active response that was set by a group */
      response: {
        category: null,
        text: "",
      },

      /* Variable that holds the value if it should be shown */
      warning: false,

      /* Variable that holds the current warning */
      warningText: "",

      /* internal Element State which we use, therefore we are independent from the parent data and can use this.elements to reset the state */
      internalElementState: this.elements,

      // size of the feedback area
      feedbackAreaMinHeight: 90,

      // array to know which groups were already correct resolved
      correctGroups: [],

      // number of wrong answers/trials
      trials: 0,

      //to save if the LBST is solved
      solved: false,

      resetLBST: false,
    };
  },
  methods: {
    restoreState: function() {
      const storeId = "LBSTGruppen" + this.id;

      const restoredState = this.$store.state.falediaState
        ? this.$store.state.falediaState[storeId]
        : false;

      if (restoredState && restoredState.data.feedbackAreaMinHeight) {
        // it is possible to receive an empty data object, so we check if there is any data by using the feedbackAreaMinHeight attribute
        this.activeGroup = restoredState.data.activeGroup; // we have to get the right element and set it based on it's id
        this.feedbackAreaMinHeight = restoredState.data.feedbackAreaMinHeight;
        let newInternalElementState = [];
        let elementsInGroups = [];

        for (let element of this.internalElementState) {
          // we create a new array, get the elements from this.internalElementState and position them based on the restoredState
          let added = false;
          for (let [
            index,
            val,
          ] of restoredState.data.internalElementState.entries() || []) {
            if (element.id == val) {
              newInternalElementState[index] = element;
              added = true;
            }
          }
          if (!added) {
            // the element was not added to the internalElementState so they have to be in one of the groups
            elementsInGroups.push(element);
          }
        }

        for (let ref in this.$refs) {
          // restore the groups
          let tempElements = [];
          for (let element of elementsInGroups) {
            for (let [index, val] of restoredState[ref].elements.entries() ||
              []) {
              if (element.id == val) {
                tempElements[index] = element;
              }
            }
          }
          this.$refs[ref][0].isCorrect = restoredState[ref].isCorrect;
          this.$refs[ref][0].groupName = restoredState[ref].groupName;
          this.$refs[ref][0].elements = tempElements;

          // removing already assigned elements would be fast, but make the code more complicated, because we are doing this once we leave it this way
        }

        //is the LBST solved
        this.solved = restoredState.data.solved;

        this.correctGroups = restoredState.data.correctGroups;

        this.internalElementState = newInternalElementState;
        this.response = restoredState.data.response;
        this.warning = restoredState.data.warning;
        this.warningText = restoredState.data.warningText;
      }
      if (!this.$store.hasModule(["nested", storeId])) {
        this.$store.registerModule(["nested", storeId], {
          namespaced: true,
          state: {
            data: {},
            refGroup0: {}, // it's very imporant to create the necessary attributes before
            refGroup1: {},
            refGroup2: {},
            refGroup3: {},
            refGroup4: {},
          },
          mutations: {
            data(state, payload) {
              // state.data = payload;
              state.data = {
                activeGroup: payload.activeGroup,
                feedbackAreaMinHeight: payload.feedbackAreaMinHeight,
                internalElementState: payload.internalElementState.map(
                  (internalElement) => internalElement.id
                ),
                response: payload.response,
                warning: payload.warning,
                warningText: payload.warningText,
                solved: payload.solved,
                correctGroups: payload.correctGroups,
              };
            },
            dataGroups(state, payload) {
              state[payload.id] = {
                elements: payload.elements.map((element) => element.id),
                groupName: payload.groupName,
                // id: payload.id, // not needed
                isCorrect: payload.isCorrect,
                // isDropDenied: payload.isDropDenied, // not needed
              };
            },
          },
        });
        // VERY IMPORTANT! If missing this state is not registered in the nested store state and will not be send to the server if another component updates it state
        this.saveStateLocal(); // save the current state, so even if we don't change anything the last state will be send to the server
      }
    },
    saveStateLocal: function() {
      const storeId = "LBSTGruppen" + this.id;
      for (let ref in this.$refs) {
        this.$store.commit("nested/" + storeId + "/dataGroups", {
          ...this.$refs[ref][0]._data,
          id: ref,
        });
      }
      this.$store.commit("nested/" + storeId + "/data", { ...this._data });
    },

    saveState: function() {
      this.saveStateLocal();
      // send data to server
      const token = localStorage.getItem("jwt");
      try {
        this.$http
          .post("/user/state", this.$store.state.nested, {
            headers: {
              Authorization: token,
            },
          })
          .then((response) => {
            localStorage.setItem("jwt", response.data.token);
            this.$store.commit("falediaState", this.$store.state.nested);
          })
          .catch(() => {
            // if we have an error, we don't save the state
            // console.log(err);
            // network error can be invalid token, be aware!
          });
      } catch (err) {
        // if we have an error, we don't save the state
        // console.log("down " + err);
      }
    },
    elementsChanged: function(changeEvent, groupName) {
      let group = groupName ? groupName : "Übersicht";

      if (changeEvent.added) {
        // we only want added events because we don't want that two events are triggered (add and remove if we move an element from one group to another)
        this.saveState();

        AppHelper.trackMatomoEvent(
          this,
          "LBSTGruppe",
          "id:16; Element: " +
            changeEvent.added.element.id +
            " hinzugefügt zur Gruppe: " +
            group +
            ";",
          this.id
        );
      } else if (changeEvent.removed) {
        AppHelper.trackMatomoEvent(
          this,
          "LBSTGruppe",
          "id:17; Element: " +
            changeEvent.removed.element.id +
            " entfernt aus Gruppe: " +
            group +
            ";",
          this.id
        );
      }
    },
    reset: function() {
      // Reset the elements
      this.internalElementState = this.elements;

      // Reset active group
      this.activeGroup = -1;
      // this.activeGroupChanged(0);

      // remove active reponse
      this.response = {
        category: null,
        text: "",
      };
      this.warning = false;

      // Reset groups
      for (let ref in this.$refs) {
        this.$refs[ref][0].reset();
      }

      this.feedbackAreaMinHeight = 90;

      this.correctGroups = [];

      this.trials = 0;
      //Not solved anymore. Results are not allowed to collapse anymore
      this.solved = false;

      this.resetLBST = true;

      this.saveState();

      AppHelper.trackMatomoEvent(this, "LBSTGruppe", "id:18; Reset;", this.id);
    },
    responseChanged: function(groupRef, response) {
      this.activeGroup = groupRef.groupId;
      this.warning = false;
      this.response = response;

      AppHelper.trackMatomoEvent(
        this,
        "LBSTGruppe",
        "id:19; Überprüfen betätigt für Gruppe: " + groupRef.groupName + ";",
        this.id
      );
      if (response.category) {
        // in this case the group was resolved correct
        AppHelper.trackMatomoEvent(
          this,
          "LBSTGruppe",
          "id:20; Korrekt gelöst Gruppe: " + groupRef.groupName + ";",
          this.id
        );

        if (
          !this.correctGroups.find(
            (element) => element.id == response.category.id
          )
        ) {
          // if the category is not in correct groups we add it
          // we have to do the check to prevent spamming the check button on the same group
          this.correctGroups.push(response.category);

          if (this.correctGroups.length == this.possibleCategories.length) {
            // if we have the same amount of correct Groups as in possibe Categories, we should be save to call it as completly solved
            AppHelper.trackMatomoEvent(
              this,
              "LBSTGruppe",
              "id:21; Letzte Gruppe gelöst. LBST erfolgreich abgeschlossen.;",
              this.id
            );
            this.solved = true;
          }
        }
      } else {
        // in this case the group wasn't resolved correct
        if (
          this.checkAfterExactItemCount
            ? this.checkAfterHowManyItems === groupRef.elements.length
            : this.checkAfterHowManyItems <= groupRef.elements.length
        ) {
          // trials counter should only be increased, when it's an semantical failure (not instructional failure)
          this.trials++;
        }
      }
      this.saveState();
    },
    groupLimitReached: function(groupName, groupCorrect) {
      if (groupCorrect) {
        this.warning = true;
        this.warningText = `Die Gruppe ${groupName} ist bereits korrekt gelöst.`;
      } else {
        this.warning = true;
        this.warningText = `Die ${groupName} ist bereits voll. Entferne zunächst ein Element.`;
        this.response = {
          category: null,
          text: "",
        };
      }
    },
    removeDropDenied: function() {
      // Remove drop denied indicator
      for (let ref in this.$refs) {
        this.$refs[ref][0].removeDropDenied();
      }
    },
    handleResize({ height }) {
      if (this.feedbackAreaMinHeight < height) {
        // ToDo: somehow the feedback area bounces a litte. could be the used border, don't now yet.
        // let randomValue = 3;
        this.feedbackAreaMinHeight = height; // + randomValue;
      }
    },
    isSafari() {
      /*
        https: //github.com/SortableJS/Sortable/issues/1571#issuecomment-753500530
        Safari has an issue with dnd so we need an detection to decide if we want to use the fallback method of sortablejs
        we just can't always use the fallback method because in firefox if we drag horizontaly on the position of another element our expandImage mechanismn is triggered :(
        */
      return AppHelper.isSafari();
    },

    videoPlayerEvent(event, name) {
      AppHelper.videoPlayerEvent(this, event, name);
    },
  },
};
</script>
